import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setAccessToken } from '../redux/auth/AuthSlice';
import { authApi } from '../api/';
import SessionInactivityPopup from '../components/onboarding/SessionInactivityPopup';
import { supabase } from '../supabaseClient';
import { set } from 'lodash';

export function WithAuth({ children }) {
	const dispatch = useDispatch();

	const accessToken = useSelector((state) => state.auth.accessToken);
	const [show, setShow] = React.useState(false);

	const checkTokenExpiration = React.useCallback(async () => {
		try {
			const { data, error } = await supabase.auth.getSession();
			// If there's an error or no data, log out and reset the access token
			if (error || !data.session) {
				setShow(true);
				return;
			}
			const new_session = await supabase.auth.refreshSession();
			if (new_session.error) {
				setShow(true);
				return;
			}
			dispatch(setAccessToken(new_session.data.session.access_token));
		} catch (err) {
			// Optional: Handle error (e.g., log out the user or show a message)
			setShow(true);
		}
	}, [dispatch]);

	const handleLoginButtonClick = () => {
		authApi.signOut();
		dispatch(setAccessToken(''));
	};

	useEffect(() => {
		// Call the function once on first render
		checkTokenExpiration();
		// Check token expiration every 60 minute (you can adjust the interval as needed)
		const tokenCheckInterval = setInterval(checkTokenExpiration, 60000 * 60);
		return () => {
			clearInterval(tokenCheckInterval);
		};
	}, [checkTokenExpiration]);

	let url = decodeURIComponent(window.location.href.split('/#')[1]);

	let urlParams = new URLSearchParams(url);
	let type = urlParams.get('type') || '';

	//Workaround for the remote single use token being clicked by email security
	if (type === 'recovery' || type === 'signup' || type === 'invite') {
		window.location.href = url;
	} else {
		return accessToken ? (
			<>
				<SessionInactivityPopup
					isOpen={show}
					onLoginClick={handleLoginButtonClick}
				/>
				{children}
			</>
		) : (
			<Navigate to="/login" />
		);
	}
}
