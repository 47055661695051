import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IdleTimerProvider } from 'react-idle-timer';
import { clearAccessToken, clearRememberMe } from '../redux/auth/AuthSlice';
import { authApi } from '../api';
import { useLocation } from 'react-router-dom';
import SessionInactivityPopup from './onboarding/SessionInactivityPopup';

const IdleTimerComponent = ({ children }) => {
	const idleTimerRef = useRef(null);
	const [hasBeenIdle, setHasBeenIdle] = React.useState(false);
	const location = useLocation();
	const rememberMe = useSelector((state) => state.auth.rememberMe);

	const dispatch = useDispatch();

	// Paths to exclude from the inactivity timer
	const pathsToExclude = [
		'/login',
		'/signup',
		'/forgot-password',
		'/reset-password',
		'/signupConfirm',
	];

	const handleOnIdle = () => {
		if (!pathsToExclude.includes(location.pathname)) {
			dispatch(clearAccessToken()); // Clear the access token
			dispatch(clearRememberMe()); // Clear remember me functionality
			setHasBeenIdle(true);
		}
	};

	const handleLoginButtonClick = () => {
		setHasBeenIdle(false);
		authApi.signOut();
	};

	const timeout = rememberMe ? 1000 * 60 * 60 * 24 : 1000 * 60 * 30; // 24 hours or 30 minutes

	return (
		<>
			<SessionInactivityPopup
				isOpen={hasBeenIdle}
				onLoginClick={handleLoginButtonClick}
			/>
			<IdleTimerProvider
				ref={idleTimerRef}
				timeout={timeout}
				onIdle={handleOnIdle}
				debounce={250}>
				{children}
			</IdleTimerProvider>
		</>
	);
};

export default IdleTimerComponent;
