import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

export const defaultErrorSnackbar = {
	show: true,
	msg: 'Oops, an error occurred. Please try again later.',
	severity: 'error',
};

export default function PositionedSnackbar({
	showmsg,
	hidemsg,
	msg,
	isInputSheet = false,
	severity = '',
}) {
	return (
		<>
			<div>
				<Snackbar
					anchorOrigin={{
						vertical: isInputSheet ? 'top' : 'bottom',
						horizontal: isInputSheet ? 'right' : 'center',
					}}
					sx={
						isInputSheet
							? {
									top: `calc(7.5vh + 20px) !important`,
									right: `calc(10vw + 90px) !important`,
							  }
							: {}
					}
					open={showmsg}
					autoHideDuration={3000}
					onClose={hidemsg}
					message={msg}>
					{severity ? (
						<Alert
							onClose={hidemsg}
							severity={severity}
							variant="filled"
							color={severity}>
							{msg}
						</Alert>
					) : null}
				</Snackbar>
			</div>
		</>
	);
}
