import BuildingDocumentsOnboarding from './popups/buildingDocuments/BuildingDocumentsOnboarding';
import DroneLogistics from './popups/droneLogistics/DroneLogistics';
import EnergyDirectOnboarding from './popups/energyDirect/EnergyDirectOnboarding';
import EnergyStarOnboarding from './popups/energyStar/EnergyStarOnboarding';

export const states = [
	'Alabama (AL)',
	'Alaska (AK)',
	'Arizona (AZ)',
	'Arkansas (AR)',
	'California (CA)',
	'Colorado (CO)',
	'Connecticut (CT)',
	'Delaware (DE)',
	'Florida (FL)',
	'Georgia (GA)',
	'Hawaii (HI)',
	'Idaho (ID)',
	'Illinois (IL)',
	'Indiana (IN)',
	'Iowa (IA)',
	'Kansas (KS)',
	'Kentucky (KY)',
	'Louisiana (LA)',
	'Maine (ME)',
	'Maryland (MD)',
	'Massachusetts (MA)',
	'Michigan (MI)',
	'Minnesota (MN)',
	'Mississippi (MS)',
	'Missouri (MO)',
	'Montana (MT)',
	'Nebraska (NE)',
	'Nevada (NV)',
	'New Hampshire (NH)',
	'New Jersey (NJ)',
	'New Mexico (NM)',
	'New York (NY)',
	'North Carolina (NC)',
	'North Dakota (ND)',
	'Ohio (OH)',
	'Oklahoma (OK)',
	'Oregon (OR)',
	'Pennsylvania (PA)',
	'Rhode Island (RI)',
	'South Carolina (SC)',
	'South Dakota (SD)',
	'Tennessee (TN)',
	'Texas (TX)',
	'Utah (UT)',
	'Vermont (VT)',
	'Virginia (VA)',
	'Washington (WA)',
	'West Virginia (WV)',
	'Wisconsin (WI)',
	'Wyoming (WY)',
];

export const PAGE_KEYS = {
	// Envelope data
	DRONE_LOGISTICS: 0,
	// Energy Data
	ENERGY_DIRECT_ACCESS: 1,
	ENERGY_STAR_ACCESS: 2,
	// Design and typical usage
	BUILDING_DOCUMENTS: 3,
	BUILDING_DETAILS: 4,
	// Guided Session
	EXPERT_REVIEW: 5,
};

export const initialOnboardingProgress = Object.keys(PAGE_KEYS).reduce(
	(acc, key) => ({ ...acc, [key]: 0 }),
	{}
);

// All documents upload related constants
export const documentKeys = {
	MECHANICAL: 'MECHANICAL',
	ELECTRICAL: 'ELECTRICAL',
	FLOOR_PLANS: 'FLOOR_PLANS',
	ELEVATIONS: 'ELEVATIONS',
	SECTION: 'SECTION',
	CONTROL: 'CONTROL',
	GLAZING_SHOP: 'GLAZING_SHOP',
	WATER_BILL: 'WATER_BILL',
	ENERGY_CODE: 'ENERGY_CODE',
	TARIFF: 'TARIFF',
};

export const buildingDocumentsDisplayName = {
	[documentKeys.MECHANICAL]: 'Mechanical drawings',
	[documentKeys.ELECTRICAL]: 'Electrical drawings',
	[documentKeys.CONTROL]: 'Control drawings',
	[documentKeys.FLOOR_PLANS]: 'Floor plans',
	[documentKeys.ELEVATIONS]: 'Elevation drawings',
	[documentKeys.SECTION]: 'Section drawings',
	[documentKeys.GLAZING_SHOP]: 'Glazing, spandrel and door shop drawings',
	[documentKeys.WATER_BILL]: 'Water bill for the past 12 months',
	[documentKeys.ENERGY_CODE]: 'Energy code compliance submittal documents',
	[documentKeys.TARIFF]: 'Utility tariff structure',
};

export const buildingDocumentsFilenamePrefix = {
	[documentKeys.MECHANICAL]: 'mechanical_drawings',
	[documentKeys.ELECTRICAL]: 'electrical_drawings',
	[documentKeys.CONTROL]: 'control_drawings',
	[documentKeys.FLOOR_PLANS]: 'floor_plans',
	[documentKeys.ELEVATIONS]: 'elevation_drawings',
	[documentKeys.SECTION]: 'section_drawings',
	[documentKeys.GLAZING_SHOP]: 'glazing_shop_drawings',
	[documentKeys.WATER_BILL]: 'water_bill',
	[documentKeys.ENERGY_CODE]: 'energy_code_compliance',
	[documentKeys.TARIFF]: 'utility_tariff',
};

export const buildingDocumentsJsonKey = {
	[documentKeys.MECHANICAL]: 'mechanicalDrawings',
	[documentKeys.ELECTRICAL]: 'electricalDrawings',
	[documentKeys.CONTROL]: 'controlDrawings',
	[documentKeys.FLOOR_PLANS]: 'floorPlans',
	[documentKeys.ELEVATIONS]: 'elevationDrawings',
	[documentKeys.SECTION]: 'sectionDrawings',
	[documentKeys.GLAZING_SHOP]: 'glazingShopDrawings',
	[documentKeys.WATER_BILL]: 'waterBill',
	[documentKeys.ENERGY_CODE]: 'energyCodeCompliance',
	[documentKeys.TARIFF]: 'utilityTariff',
};

export const buildingDocumentsDescriptions = {
	[documentKeys.MECHANICAL]:
		'Mechanical drawings include detailed plans and specifications for HVAC systems, ductwork, piping layouts, equipment schedules, control systems, and mechanical room layouts.)',
	[documentKeys.ELECTRICAL]:
		'Electrical drawings include detailed plans for power distribution, lighting, wiring, and circuit layouts, along with the placement of devices like outlets and switches. They also feature power-rise diagrams and schedules for panels, lighting fixtures, and equipment, providing specifications and load details. ',
	[documentKeys.CONTROL]:
		'Control drawings are technical drawings that illustrate (typically as a block diagram) the control systems and components used. They also detail sequences of operations to ensure proper functionality and integration of the control systems.',
	[documentKeys.FLOOR_PLANS]:
		'Floor plans depict the shape of spaces and the layout of walls, doors, rooms, and windows on each level of a building, along with precise dimensions.',
	[documentKeys.ELEVATIONS]:
		'Elevation drawings are technical drawings that outline a view of the building seen from one side, a flat representation of one façade along with its dimensions, for each cardinal direction (north, south, east, and west).',
	[documentKeys.SECTION]:
		"Section drawings provide a vertical cut-through view of a building, revealing the internal structure, material layers, and construction details, along with dimensions that aren't visible in standard floor plans or elevations",
	[documentKeys.GLAZING_SHOP]:
		'Drawings that detail the design, material specifications, dimensions and installation of windows, spandrels, and doors in a building.',
	[documentKeys.WATER_BILL]:
		'Please upload water bills for the past 12 months.',
	[documentKeys.ENERGY_CODE]:
		"Documentation that verifies the building's design and construction comply with the relevant energy codes and regulations. ",
	[documentKeys.TARIFF]:
		'Utility tariff structure document that outline the charges for the building’s energy consumption.',
};

export const requiredBuildingDocuments = [
	buildingDocumentsJsonKey[documentKeys.MECHANICAL],
	buildingDocumentsJsonKey[documentKeys.ELECTRICAL],
	buildingDocumentsJsonKey[documentKeys.FLOOR_PLANS],
	buildingDocumentsJsonKey[documentKeys.ELEVATIONS],
	buildingDocumentsJsonKey[documentKeys.SECTION],
];

export const droneLogisticsPageKeys = {
	DATE_SELECTION: 0,
	TAKEOFF_LOCATIONS: 1,
	CHARGING_STATIONS: 2,
	PREFERRED_SET_SUCCESS: 3,
	SAVING: 4,
};
export const popupTitles = {
	[PAGE_KEYS.DRONE_LOGISTICS]: 'Drone Logistics',
	[PAGE_KEYS.ENERGY_DIRECT_ACCESS]: 'Energy Direct Access',
	[PAGE_KEYS.ENERGY_STAR_ACCESS]: 'Energy Star Access',
	[PAGE_KEYS.BUILDING_DOCUMENTS]: 'Building Documents',
};
export const popupSubtitles = {
	[PAGE_KEYS.DRONE_LOGISTICS]:
		'Confirm your preferences for the upcoming drone flight.',
	[PAGE_KEYS.ENERGY_DIRECT_ACCESS]:
		'Sets up the connection to your Energy Direct account.',
	[PAGE_KEYS.ENERGY_STAR_ACCESS]:
		'Sets up the connection to your Energy Star account.',
	[PAGE_KEYS.BUILDING_DOCUMENTS]: 'Use Ctrl or Shift to upload multiple files.',
};
export const inputModule = {
	[PAGE_KEYS.DRONE_LOGISTICS]: DroneLogistics,
	[PAGE_KEYS.ENERGY_DIRECT_ACCESS]: EnergyDirectOnboarding,
	[PAGE_KEYS.ENERGY_STAR_ACCESS]: EnergyStarOnboarding,
	[PAGE_KEYS.BUILDING_DOCUMENTS]: BuildingDocumentsOnboarding,
};
export const initialDroneLogisticsData = {
	selectedDates: [],
	chargingLocations: '',
	preferredLocations: '',
	takeoffLocations: {},
	additionalNotes: '',
	flightPlanId: '',
};

export const totalDroneLogisticsPages = 3;

export const calculateTotalProgress = (tracking) => {
	if (!tracking) {
		return 0;
	}

	const sectionsList = Object.values(PAGE_KEYS);
	const totalProgress = sectionsList.reduce(
		(acc, key) => acc + (tracking[key] ?? 0),
		0
	);

	// Return the overall progress percentage
	return Math.round(totalProgress / sectionsList.length);
};
